<template>
  <!-- 当应用为空时首页和我的应用显示 -->
  <div class="app-lication">
    <div class="prompt">您还没有添加应用，添加应用后开启你的变现之旅</div>
    <div class="add-app">
      <img src="@/assets/images/homePage/add-app.png" alt="图片" />
      <el-button round @click="addLication">
        <span
          >添加应用</span
        ></el-button
      >
    </div>
    <div class="app-footer">
      <Footer></Footer>
    </div>

    <el-dialog
      title="添加我的应用"
      :visible.sync="dialogVisible"
      width="700px"
      :before-close="handleClose"
      top="10px"
    >
      <img
        class="close"
        src="@/assets/images/homePage/close.png"
        @click="handleClose"
        alt="图片"
      />

      <div class="box-line">
        <div class="box-name">
          <img
            v-show="cateId === 2"
            class="star"
            src="@/assets/images/homePage/star.png"
            alt="图片"
          />
          <span>应用名称：</span>
        </div>
        <input v-model="name" maxlength="1000" placeholder="例如:我的应用" />
      </div>
      <div class="box-line">
        <div class="box-name">
          <img
            class="star"
            src="@/assets/images/homePage/star.png"
            alt="图片"
          />
          <span>应用地址：</span>
        </div>
        <input
          v-model="uri"
          maxlength="1000"
          placeholder="例如：http：//example.com"
        />
      </div>
      <div class="box-line">
        <div class="box-name">
          <img
            class="star"
            src="@/assets/images/homePage/star.png"
            alt="图片"
          />
          <span>平台类别：</span>
        </div>
        <div class="category">
          <div
            v-for="item in PlatformTypes"
            :class="[
              'category-item',
              { 'category-item-active': cateId === item.id },
            ]"
            :key="item.id"
            @click="cateId = item.id"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="box-line">
        <div class="box-name">
          <img
            class="star"
            src="@/assets/images/homePage/star.png"
            alt="图片"
          />
          <span>行业类别：</span>
        </div>
        <el-select v-model="industryId" placeholder="请选择">
          <el-option
            v-for="item in IndustryTypes"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="box-line">
        <div class="box-search">
          <div class="box-name">
            <img
              class="star"
              src="@/assets/images/homePage/star.png"
              alt="图片"
            />
            <span>执行国家(地区)：</span>
          </div>
          <div class="search">
            <el-select v-model="countrysItem" filterable placeholder="请选择">
              <el-option
                v-for="item in countrysOptions"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
            <el-button class="search-add" @click="countryAdd">添加</el-button>
          </div>
        </div>
        <div class="countrys">
          <div
            class="country-box"
            v-for="(item, index) in countrys"
            :key="index"
          >
            {{ item.name }}
            <img
              class="country-close"
              src="@/assets/images/homePage/country-close.png"
              alt="图片"
              @click="deletedCountry(item.id)"
            />
          </div>
        </div>
      </div>
      <div class="box-line">
        <div class="box-name">
          <img
            class="star"
            src="@/assets/images/homePage/star.png"
            alt="图片"
          />
          <span>应用介绍：</span>
        </div>

        <el-input
          type="textarea"
          :rows="4"
          :placeholder="yyjs"
          v-model="summary"
          maxlength="1000"
        >
        </el-input>
      </div>

      <div class="buttons">
        <el-button class="cancel" @click="handleClose" round>取消</el-button>
        <el-button class="add" @click="setCreateApp" round>添加应用</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Footer from "@/components/Footer/homePageFooter.vue";
import { getCountryAll, setCreateApp } from "@/api/app";
import { IndustryTypes, PlatformTypes } from "@/utils/data";
export default {
  components: { Footer },
  data() {
    return {
      dialogVisible: false,
      countrys: [],
      copyCountrysOptions: [],
      countrysOptions: [],
      IndustryTypes,
      PlatformTypes,
      name: "",
      uri: "",
      industryId: null,
      cateId: null,
      summary: null,
      countrysItem: null,
      yyjs: "应用介绍建议填写详细，以便管理员审核；介绍内容包含且不限于，平台会员量，日活量，是否有执行过同类业务经验，执行过哪些客服的项目等等；输入的字符数，小于2000个字符；",
    };
  },
  created() {
    this.getCountryAll();
    console.log("this.$router.path", this.$route.path);
  },
  methods: {
    addLication() {
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    //获取全部国家
    getCountryAll() {
      getCountryAll().then((res) => {
        if (res.status === 200) {
          this.countrysOptions = res.data;
          this.copyCountrysOptions = res.data;
          console.log("this.countrysOptions", this.countrysOptions);
        }
      });
    },
    //删除国家
    deletedCountry(id) {
      this.countrys = this.countrys.filter((item) => {
        return item.id !== id;
      });
    },
    //添加国家
    countryAdd() {
      if (!this.countrysItem && this.countrysItem !== 0) {
        this.$message({
          message: "请选择需要添加的国家地区",
          type: "warning",
        });
        return;
      }
      const index = this.countrys.findIndex((item) => {
        return item.id === this.countrysItem;
      });
      if (index !== -1) {
        this.$message({
          message: "该国家地区已添加",
          type: "warning",
        });
        return;
      }
      if (this.countrys.length === 5) {
        this.$message({
          message: "最多只能选择5个国家(地区)",
          type: "warning",
        });
        return;
      }
      const item = this.copyCountrysOptions.find((item) => {
        return item.id === this.countrysItem;
      });
      this.countrys.push(item);
    },
    //创建应用
    setCreateApp() {
      const countries = [];
      this.countrys.map((item) => {
        countries.push(item.id);
      });
      const params = {
        name: this.name,
        uri: this.uri,
        industryId: this.industryId,
        cateId: this.cateId,
        summary: this.summary,
        countries: countries,
      };

      if (!this.name) {
        this.$message({
          message: "应用名称不能为空",
          type: "warning",
        });
        return;
      }
      if (!this.uri && this.cateId) {
        this.$message({
          message: "应用地址不能为空",
          type: "warning",
        });
        return;
      }
      if (!this.cateId) {
        this.$message({
          message: "平台类别不能为空",
          type: "warning",
        });
        return;
      }
      if (!this.industryId) {
        this.$message({
          message: "行业类别不能为空",
          type: "warning",
        });
        return;
      }
      if (countries.length === 0) {
        this.$message({
          message: "执行国家(地区)至少选择一个",
          type: "warning",
        });
        return;
      }
      if (!this.summary) {
        this.$message({
          message: "应用介绍不能为空",
          type: "warning",
        });
        return;
      }
      setCreateApp(params).then((res) => {
        if ((res.status = 200)) {
          this.$message({
            showClose: true,
            message: "新增成功",
            type: "success",
          });
          this.dialogVisible = false;
          this.$store.commit("application/setIsApplication", true);
          localStorage.setItem("application", true);
          this.$emit("refresh");
          if (this.$route.path !== "/application/index") {
            this.$router.push("/application/index");
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.app-lication {
  .prompt {
    padding-top: 123px;
    text-align: center;
    font-size: 14px;
    color: #4578ff;
  }
  .add-app {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 288px;
      height: auto;
      margin-top: 123px;
    }
    .el-button {
      width: 160px;
      height: 40px;
      background: #4578ff;
      color: #fff;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        position: relative;
        top: 2.3px;
        width: 16px;
        height: 16px;
        margin: 0 16px 0 0;
      }
    }
  }
  .app-footer {
    padding: 0 20px;
    margin-top: 200px;
  }
  /deep/ .el-dialog__header {
    padding: 30px 30px 10px 30px;
  }
  /deep/.el-dialog__body {
    padding: 25px 30px 30px 25px;
  }
  /deep/ .el-dialog__title {
    font-size: 26px;
    font-weight: bold;
    color: #06052b;
  }
  /deep/.el-dialog__headerbtn {
    display: none;
  }
  .close {
    position: absolute;
    top: 36px;
    right: 30px;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
  .box-line {
    display: flex;
    flex-direction: column;
    margin-bottom: 26px;
    position: relative;
    .box-name {
      display: flex;
      align-items: center;
      font-size: #06052b;
      .star {
        width: 12px;
        height: 12px;
      }
    }
    input {
      margin-top: 11px;
      padding-left: 14px;
      background-color: #fafafa;
      height: 36px;
      line-height: 36px;
      border: 1px solid #dcdfe6;
      border-radius: 4px;
    }
    .category {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;
      .category-item {
        width: 110px;
        height: 36px;
        line-height: 36px;
        background: #fafafa;
        border-radius: 4px;
        text-align: center;
        color: #06052b;
        cursor: pointer;
      }
      .category-item-active {
        color: #fff;
        background-color: #4578ff;
      }
    }
    .el-select {
      background-color: #fafafa;
      margin-top: 16px;
    }
    .box-search {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .search {
        display: flex;
        align-items: center;
        width: 220px;
        .el-select {
          width: 150px;
          margin: 0;
          /deep/ .el-input__inner {
            height: 36px;
          }
        }
        .search-add {
          width: 50px;
          height: 36px;
          padding: 0;
          background: #4578ff;
          color: #fff;
          margin-left: 10px;
        }
      }
    }

    .countrys {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
      .country-box {
        position: relative;
        height: 32px;
        padding: 0 14px;
        line-height: 32px;
        text-align: center;
        background: #4578ff;
        border-radius: 4px;
        color: #fff;
        margin-right: 24px;
        margin-bottom: 10px;
        cursor: pointer;
        .country-close {
          position: absolute;
          width: 10px;
          height: 10px;
          right: -5px;
          top: -5px;
        }
      }
    }
    .el-textarea {
      margin-top: 16px;
    }
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 54px;
    .cancel {
      border-color: #cccccc;
      background-color: #fff;
      color: #606266;
      height: 36px;
    }
    .add {
      width: 136px;
      height: 36px;
      padding: 0;
      background: #4578ff;
      color: #fff;
    }
  }
}
</style>