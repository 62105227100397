<template>
  <div v-if="$store.state.application.isOK">
    <div class="home-page" v-if="$store.state.application.isApplication">
      <div class="cards">
        <div
          v-for="(item, index) in cardList"
          :key="index"
          :class="['card-box', { 'card-box2': item.type === 2 }]"
        >
          <img
            class="sigh"
            src="@/assets/images/homePage/sigh.png"
            alt="图片"
          />

          <div class="img-box">
            <img class="icon" :src="item.img" alt="图片" />
            <div class="info">
              <div class="name">{{ item.name }}</div>
              <div class="number">{{ item.number | modifyValues }}</div>
            </div>
          </div>

          <div class="all-hits">
            <div>{{ item.title }}</div>
            <div>{{ item.numberAll | modifyNumberAll }}</div>
          </div>
        </div>
      </div>
      <!-- 数据报表 -->
      <div class="data-report">
        <div
          class="fold-line"
          v-on:mouseover="stopMove()"
          v-on:mouseout="goMove()"
          v-loading="loading1"
          element-loading-text="加载中..."
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(255, 255, 255, 0.1)"
        >
          <div class="operate">
            <el-select
              v-model="appId"
              placeholder="应用选择"
              size="small"
              @change="getReportChart"
            >
              <el-option
                v-for="item in appList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <div class="date-list">
              <div
                v-for="(item, index) in datelist"
                :key="index"
                @click="getNextDate(index)"
                :class="[{ active: active === index }]"
              >
                {{ item }}
              </div>
            </div>
            <el-date-picker
              v-model="pickerData"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              size="small"
              :picker-options="pickerOptions"
              @change="changePickerData"
              popper-class="picker-date-style"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </div>
          <div
            class="chart"
            ref="chart"
            style="width: 725px; height: 350px"
          ></div>
        </div>
        <div
          class="progress"
          v-loading="loading"
          element-loading-text="加载中..."
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(255, 255, 255, 0.1)"
        >
          <div
            class="progress-box"
            v-for="(item, index) in progressLis"
            :key="index"
          >
            <el-progress
              type="circle"
              v-bind:percentage="item.number | modifyPercentage"
              :show-text="false"
              :stroke-width="10"
              :width="124"
              :color="item.color"
              define-back-color="#F5F6FA"
            ></el-progress>
            <div class="name">{{ item.name }}</div>
            <div class="float-box">
              <p>与上周相比</p>
              <div class="number">
                <span>{{ item.number }}%</span>
                <img
                  v-if="item.type === 1 && item.number !== 0"
                  src="@/assets/images/homePage/upward.png"
                  alt="图片"
                />
                <img
                  v-if="item.type === 2 && item.number !== 0"
                  src="@/assets/images/homePage/downward.png"
                  alt="图片"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 任务看板 -->
      <div class="task-kanban-title">任务看板</div>
      <div class="task-kanban">
        <div class="task-kanban-states">
          <div
            v-for="(item, index) in taskKanbanStatesList"
            :key="index"
            :class="['states-box', { 'active-style': index === categoryId }]"
            @click="cheageCategoryId(index)"
          >
            {{ item.name }}
          </div>
        </div>

        <div class="task-kanban-cards" v-if="recordList.length !== 0">
          <div class="card" v-for="(item, index) in recordList" :key="index">
            <div
              class="state"
              :style="{
                background:
                  item.cateId === 1
                    ? '#E3EAFD'
                    : item.cateId === 2
                    ? '#E6F6F6'
                    : '#F8D8D5',
                color:
                  item.cateId === 1
                    ? '#4578FF'
                    : item.cateId === 2
                    ? '#68EBB5'
                    : '#FF7457',
              }"
            >
              {{
                item.cateId === 1
                  ? "项目提醒"
                  : item.cateId === 2
                  ? "QC提醒"
                  : "订单提醒"
              }}
            </div>
            <div class="name">{{ item.title }}</div>
            <div class="date">接收日期:{{ item.dateline }}</div>
            <div class="line"></div>
            <div class="detail" @click="detailShow(item)">查看详情</div>
          </div>
        </div>
        <div v-else class="task-kanban-cards nodata">
          <div>暂无数据</div>
        </div>
      </div>

      <el-dialog
        title="提示"
        :visible.sync="detailVisible"
        width="480px"
        :before-close="detailClose"
        :close-on-click-modal="false"
      >
        <img
          class="close"
          @click="detailClose"
          src="@/assets/images/close.png"
          alt="图片"
        />
        <div class="title">查看详情</div>
        <div class="content">
          {{ detailContent }}
        </div>
      </el-dialog>
      <Footer></Footer>
    </div>
    <Application v-else></Application>
  </div>
</template>
<script>
import Footer from "@/components/Footer/homePageFooter.vue";
import Application from "@/components/Application";
import { homePageCardList, homePageProgressList } from "@/utils/data";
import echarts from "../../utils/chart";
import {
  getReportSummary,
  getReportToday,
  getApps,
  getReportChart,
  getMessages,
  setReadMessage,
  getMessagesUnreadCount,
  getComparison,
} from "@/api/app";
import { getNextDate } from "@/utils/date";
export default {
  components: { Footer, Application },
  filters: {
    modifyValues(value) {
      if (value >= 1000) {
        value = String(value);
        let end = value.substring(value.length - 3, value.length);
        let start = value.substring(0, value.length - 3);
        value = start + "," + end;
        console.log(end, start);
      }
      return value;
    },
    modifyPercentage(value) {
      if (value > 100) {
        value = 100;
      }
      return value;
    },
    modifyNumberAll(value) {
      //如果value大于等于10万
      if (value >= 100000) {
        value = parseInt(value / 10000) + "万";
      }
      return value;
    },
  },
  data() {
    return {
      cardList: homePageCardList,
      progressLis: homePageProgressList,
      loading: true,
      loading1: true,
      recordList: [],
      value1: "",
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
      ],
      datelist: ["近7天", "近30天", "近3个月", "近半年"],
      taskKanbanStatesList: [
        {
          name: "全部",
          value: 0,
        },
        {
          name: "项目提醒",
          value: 1,
        },
        {
          name: "QC提醒",
          value: 2,
        },
        {
          name: "订单提醒",
          value: 3,
        },
      ],
      appList: [],
      appId: null,
      dateline: [], //日期数据
      clicks: [], //点击数据
      completes: [], //完成数据
      unqualifieds: [], //不合格数据
      startDate: null,
      endDate: null,
      pickerData: null,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        },
      },
      categoryId: 0,
      detailVisible: false,
      detailContent: null,
      active: 0,

      //实验图表滚动
      chartOptions: null,
      timeTick: null,
    };
  },
  watch: {
    isApplication() {
      this.$nextTick(() => {
        this.getEchartData();
      });
    },
    isOK(newValue) {
      if (newValue) {
        //数据报表
        this.getReportSummary();
        this.getReportToday();

        //初始化筛选日期
        this.startDate = getNextDate(new Date(), -6);
        this.endDate = getNextDate(new Date(), 0);
        //echars
        if (this.$store.state.application.isApplication) {
          this.getApps();
        }
        //获取消息
        this.getMessages();
        this.autoMove();
      }
    },
  },
  mounted() {
    //数据报表
    this.getReportSummary();
    this.getReportToday();

    //初始化筛选日期
    this.startDate = getNextDate(new Date(), -6);
    this.endDate = getNextDate(new Date(), 0);
    //echars
    if (this.$store.state.application.isApplication) {
      this.getApps();
    }
    //获取消息
    this.getMessages();
    this.autoMove();
  },
  computed: {
    isApplication() {
      return this.$store.state.application.isApplication;
    },
    isOK() {
      return this.$store.state.application.isOK;
    },
  },
  methods: {
    //数据报表汇总数据
    getReportSummary() {
      getReportSummary().then((res) => {
        this.cardList[0].numberAll = res.data.clicks;
        this.cardList[1].numberAll = res.data.completes;
        this.cardList[2].numberAll = res.data.unqualified;
        this.cardList[3].numberAll = res.data.earning;
      });
    },
    //数据报表当天数据
    getReportToday() {
      getReportToday().then((res) => {
        this.cardList[0].number = res.data.clicks;
        this.cardList[1].number = res.data.completes;
        this.cardList[2].number = res.data.unqualified;
        this.cardList[3].number = res.data.earning;
      });
    },
    //获取应用列表
    getApps() {
      const params = {
        page: 1,
        size: 100,
      };
      getApps(params).then((res) => {
        if (res.status === 200) {
          this.appList = res.data.data;
          this.appId = res.data.data[0].id;
          console.log("this.appList", this.appList);
          //获取数据报表
          this.getReportChart();
          //本周与上周数据
          this.getComparison();
        }
      });
    },
    //获取数据报表
    getReportChart() {
      const params = {
        appId: this.appId,
        startDate: this.startDate,
        endDate: this.endDate,
      };
      getReportChart(params).then((res) => {
        //console.log(123445, res.data);
        if (res.status === 200) {
          let clickData = res.data[0]; //点击数据
          let completeData = res.data[1]; //完成数据
          let unqualifiedData = res.data[2]; //不合格数据
          this.dateline = clickData.dateline.map((item) => {
            return item.substring(0, 10);
          });
          this.clicks = clickData.amount;
          this.completes = completeData.amount;
          this.unqualifieds = unqualifiedData.amount;
          this.loading1 = false;
          console.log("this.loading1", this.loading1);
          this.getEchartData();
          this.getComparison();
        }
      });
    },
    getEchartData() {
      const chart = this.$refs.chart;
      if (chart) {
        const myChart = echarts.init(chart);
        const option = {
          tooltip: {
            trigger: "axis",
          },
          legend: {
            data: ["点击量", "完成量", "不合格量"],
            padding: [20, 0, 0, 0],
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },

          xAxis: {
            type: "category",
            boundaryGap: true,
            data: this.dateline,

            splitLine: {
              show: false,
            },
          },
          yAxis: {
            type: "value",
          },
          series: [
            {
              name: "点击量",
              type: "line",
              smooth: true,
              lineStyle: {
                color: "#4578FF",
              },
              itemStyle: {
                color: "#4578FF",
              },
              data: this.clicks,
            },
            {
              name: "完成量",
              type: "line",
              smooth: true,
              lineStyle: {
                color: "#68EBB5",
              },
              itemStyle: {
                color: "#68EBB5",
              },
              data: this.completes,
            },
            {
              name: "不合格量",
              type: "line",
              smooth: true,
              lineStyle: {
                color: "#FF6A6A",
              },
              itemStyle: {
                color: "#FF6A6A",
              },
              data: this.unqualifieds,
            },
          ],
          dataZoom: [
            {
              type: "slider", //隐藏或显示（true）组件
              show: true,
              backgroundColor: "rgb(19, 63, 100)", // 组件的背景颜色。
              fillerColor: "rgb(16, 171, 198)", // 选中范围的填充颜色。
              borderColor: "rgb(19, 63, 100)", // 边框颜色
              showDetail: false, //是否显示detail，即拖拽时候显示详细数值信息
              startValue: 0,
              endValue: 5,
              filterMode: "empty",
              width: "50%", //滚动条高度
              height: 8, //滚动条显示位置
              left: "center",
              //zoomLoxk: true, // 是否锁定选择区域（或叫做数据窗口）的大小
              handleSize: 0, //控制手柄的尺寸
              bottom: 0, // dataZoom-slider组件离容器下侧的距离
            },
            {
              //没有下面这块的话，只能拖动滚动条，鼠标滚轮在区域内不能控制外部滚动条
              type: "inside",
              zoomOnMouseWheel: false, //滚轮是否触发缩放
              moveOnMouseMove: true, //鼠标滚轮触发滚动
              moveOnMouseWheel: true,
            },
          ],
        };

        myChart.setOption(option);

        this.chartOptions = option;
      }
    },
    autoMove() {
      this.timeTick = setInterval(() => {
        //console.log(`${this.getCurrentTime()}==>自动播放中..`);
        if (this.chartOptions.dataZoom[0].endValue === this.clicks.length - 1) {
          this.chartOptions.dataZoom[0].endValue = 5;
          this.chartOptions.dataZoom[0].startValue = 0;
        } else {
          this.chartOptions.dataZoom[0].endValue =
            this.chartOptions.dataZoom[0].endValue + 1;
          this.chartOptions.dataZoom[0].startValue =
            this.chartOptions.dataZoom[0].startValue + 1;
        }

        const chart = this.$refs.chart;
        if (chart) {
          const myChart = echarts.init(chart);
          myChart.setOption(this.chartOptions);
        }
      }, 3500);
    },
    goMove() {
      //console.log(`${this.getCurrentTime()}==>继续自动播放..`);
      this.autoMove();
    },
    stopMove() {
      //console.log(`${this.getCurrentTime()}==>被暂停了..`);
      clearInterval(this.timeTick);
    },
    getCurrentTime() {
      //获取当前时间并打印
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      let hh = new Date().getHours();
      let mf =
        new Date().getMinutes() < 10
          ? "0" + new Date().getMinutes()
          : new Date().getMinutes();
      let ss =
        new Date().getSeconds() < 10
          ? "0" + new Date().getSeconds()
          : new Date().getSeconds();
      return yy + "/" + mm + "/" + dd + " " + hh + ":" + mf + ":" + ss;
    },
    //获取日期
    getNextDate(index) {
      this.endDate = getNextDate(new Date(), 0);
      this.active = index;
      this.loading1 = true;
      switch (index) {
        case 0:
          this.startDate = getNextDate(new Date(), -6);
          this.getReportChart();
          break;
        case 1:
          this.startDate = getNextDate(new Date(), -29);
          this.getReportChart();
          break;
        case 2:
          this.startDate = getNextDate(new Date(), -89);
          this.getReportChart();
          break;
        case 3:
          this.startDate = getNextDate(new Date(), -179);
          this.getReportChart();
          break;
      }
    },
    //日期区间
    changePickerData() {
      console.log(this.pickerData);
      if (!this.pickerData) {
        this.endDate = getNextDate(new Date(), 0);
        this.startDate = getNextDate(new Date(), -6);
        this.active = 0;
      } else {
        this.startDate = this.pickerData[0];
        this.endDate = this.pickerData[1];
        this.active = null;
      }
      this.getReportChart();
    },
    //任务看板消息
    getMessages() {
      const params = {
        page: 1,
        size: 10,
        reading: false,
        categoryId: this.categoryId === 0 ? null : this.categoryId,
      };
      getMessages(params).then((res) => {
        console.log("我i的消息", res.data);
        if (res.status === 200) {
          this.recordList = res.data.data.slice(0, 4);
        }
      });
    },
    //筛选消息
    cheageCategoryId(index) {
      this.categoryId = index;
      this.getMessages();
    },
    detailClose() {
      this.detailVisible = false;
    },
    detailShow(data) {
      this.detailVisible = true;
      this.detailContent = data.content;
      const arr = [];
      arr.push(data.id);
      this.setReadMessage(arr);
    },
    //已读消息
    setReadMessage(arr) {
      setReadMessage(arr).then((res) => {
        if (res.status === 200) {
          this.getMessages();
          this.getMessagesUnreadCount();
        }
      });
    },
    //获取全部未读消息数量
    getMessagesUnreadCount() {
      getMessagesUnreadCount().then((res) => {
        if (res.status === 200) {
          this.$store.commit("application/setNoMessageNumber", res.data);
        }
      });
    },
    //首页本周与上周数据对比接口
    getComparison() {
      const params = {
        appId: this.appId,
      };
      getComparison(params).then((res) => {
        if (res.status === 200) {
          console.log("数据对比", res.data);
          let lastWeekData = res.data[0];
          let weekData = res.data[1];
          const dataClicks = this.processingData(
            lastWeekData.clicks,
            weekData.clicks
          );
          const dataCompletes = this.processingData(
            lastWeekData.completes,
            weekData.completes
          );
          const dataUnqualified = this.processingData(
            lastWeekData.unqualified,
            weekData.unqualified
          );
          const dataEarning = this.processingData(
            lastWeekData.earning,
            weekData.earning
          );
          this.progressLis[0].type = dataClicks.type;
          this.progressLis[0].number = dataClicks.number;
          this.progressLis[1].type = dataCompletes.type;
          this.progressLis[1].number = dataCompletes.number;
          this.progressLis[2].type = dataUnqualified.type;
          this.progressLis[2].number = dataUnqualified.number;
          this.progressLis[3].type = dataEarning.type;
          this.progressLis[3].number = dataEarning.number;
          this.loading = false;
          console.log("this.progressLis", this.progressLis);
        }
      });
    },
    //传入参数(本周与上周数据)处理数据
    processingData(value1, value2) {
      console.log("value1", typeof value1, "value2", typeof value2);
      let data = {
        type: 1,
        number: null,
      };
      // 上周数据为0，本周数据不为0
      if (value1 === 0 && value2 !== 0) {
        data.number = 100;
      } else if (value1 !== 0 && value2 === 0) {
        //上周数据不为0,本周数据为0
        data.type = 2;
        data.number = 100;
      } else if (value1 === 0 && value2 === 0) {
        //上周数据为0,本周数据为0
        data.number = 0;
      } else {
        if (value1 === value2) {
          data.number = 0;
        } else if (value1 > value2) {
          data.type = 2;
          data.number = parseInt(((value1 - value2) * 100) / value1);
        } else {
          data.number = parseInt(((value2 - value1) * 100) / value1);
        }
      }

      return data;
    },
  },
  destroyed() {
    this.stopMove();
  },
};
</script>


<style lang="scss" scoped>
.home-page {
  padding: 20px;

  .cards {
    display: flex;
    justify-content: space-between;

    .card-box {
      width: 279px;
      height: 157px;
      background: #ffffff;
      border-radius: 4px;
    }

    .card-box2 {
      background: linear-gradient(149deg, #5da5ff 0%, #4170ed 100%);
    }
  }
}

.card-box {
  .sigh {
    float: right;
    margin: 18px 18px 0 0;
    width: 18px;
    height: 18px;
  }

  .img-box {
    display: flex;
    height: 68px;
    margin-top: 31px;
    align-items: center;
    margin-left: 25px;

    .icon {
      width: 55px;
      height: 55px;
    }

    .info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 19px;
      color: #06052b;

      .name {
        font-size: 16px;
      }

      .number {
        font-weight: bold;
        font-size: 32px;
      }
    }
  }

  .all-hits {
    width: calc(100% - 48px);
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    margin-top: 13px;
    color: #606266;
    font-size: 14px;
  }
}

.card-box2 {
  .img-box {
    .info {
      color: #fff;
    }
  }

  .all-hits {
    color: #fff;
  }
}

.data-report {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  .fold-line {
    width: 725px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .operate {
      display: flex;
      justify-content: space-between;
      width: 725px;

      .el-select {
        width: 100px;
      }

      .date-list {
        display: flex;
        justify-content: space-around;
        height: 32px;
        background-color: #ffffff;
        width: 262px;

        div {
          line-height: 32px;
          font-size: 14px;
          color: #06052b;
          cursor: pointer;
        }

        .active {
          color: #4170ed;
        }
      }
    }

    .chart {
      background-color: #fff;
    }
  }

  .progress {
    width: 431px;
    height: 401px;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    display: flex;
    flex-wrap: wrap;

    .progress-box {
      width: 215.5px;
      height: 200.5px;
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;

      .el-progress {
        margin-top: 30px;
      }

      .name {
        margin-top: 5px;
        margin-left: auto;
        margin-right: auto;
      }

      .float-box {
        position: absolute;
        width: 100px;
        top: 70px;
        left: 50%;
        transform: translateX(-50%);

        p {
          font-size: 12px;
          color: #606266;
          text-align: center;
        }

        .number {
          display: flex;
          align-items: flex-end;
          margin-top: 8px;
          width: 100%;
          justify-content: center;

          span {
            font-size: 27px;

            font-weight: bold;
          }

          img {
            width: 14px;
            height: 14px;
            margin-bottom: 6px;
            margin-left: 3px;
          }
        }
      }
    }
  }
}

.task-kanban-title {
  font-size: 16px;
  color: #333333;
  margin-top: 26px;
  margin-bottom: 18px;
}

.task-kanban {
  width: calc(100% - 50px);
  padding: 25px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;

  .task-kanban-states {
    margin-top: 5px;
    display: flex;

    .states-box {
      margin-right: 34px;
      height: 36px;
      line-height: 36px;
      padding: 0 18px;
      background-color: #f5f7fd;
      border-radius: 4px;
      color: #06052b;
      font-size: 14px;
      cursor: pointer;
    }

    .active-style {
      color: #4170ed;
    }
  }

  .task-kanban-cards {
    display: flex;
    justify-content: flex-start;
    margin-top: 29px;
    min-height: 163px;

    .card {
      width: 250px;
      height: 163px;
      background: #f5f7fd;
      opacity: 1;
      margin-right: calc((100% - 1000px) / 3);

      .state {
        float: right;
        width: 60px;
        height: 24px;
        font-size: 0.6875em;
        text-align: center;
        line-height: 24px;
        border-radius: 0px 4px 0px 4px;
      }

      .name {
        clear: both;
        margin-top: 3px;
        margin-left: 17px;
        font-size: 18px;
        color: #333333;
      }

      .date {
        margin-top: 31px;
        margin-left: 17px;
        font-size: 14px;
        color: #999999;
      }

      .line {
        height: 0;
        width: 100%;
        margin-top: 15px;
        border-top: 1px solid #e5e5e5;
      }

      .detail {
        text-align: center;
        height: 47px;
        line-height: 47px;
        font-size: 14px;
        color: #333333;
        cursor: pointer;
      }
    }

    .card:nth-child(4n) {
      margin-right: 0;
    }
  }

  .nodata {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ae7777;
  }
}

.el-dialog__wrapper {
  /deep/ .el-dialog__header {
    display: none;
  }

  /deep/ .el-dialog__body {
    padding: 20px;
  }

  .close {
    float: right;
    width: 14px;
    cursor: pointer;
  }

  .title {
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    color: #06052b;
  }

  .content {
    margin-top: 20px;
  }
}
</style>